
import { Swiper, SwiperSlide } from 'swiper/vue'
import SwiperCore, { Pagination } from 'swiper'
import { defineComponent, onMounted, reactive, toRefs, watch } from 'vue'
import lightLine from '@/assets/images/light_line.png'
import { useStore } from '@/store'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    Swiper,
    SwiperSlide
  },
  setup() {
    SwiperCore.use([Pagination])

    const { t } = useI18n()
    const store = useStore()
    const pagination = {
      clickable: true,
      renderBullet: (index: number, className: string) => {
        // eslint-disable-next-line
        return `<span class="${className} J_Advantage_Bullet">
          <img src="${require('@/assets/images/advantage_icon/icon' + index + '.png')}">
        </span>`
      }
    }
    const dataMap = reactive({
      slogonImage: ''
    })

    onMounted(() => {
      const advantage = window.document.querySelector('.J_Advantage')
      if (store.state.app.device === 1 && advantage) {
        advantage.classList.add('m-advantage')
      }
      const el = [...window.document.querySelectorAll('.J_Advantage_Bullet')]
      el.map(v => {
        if (!v) return
        v.addEventListener('mouseenter', (e: any) => {
          e.target.click()
        })
      })
    })

    watch(() => store.state.app.config.resource, (n, o) => {
      if (n && n !== o) {
        dataMap.slogonImage = `${n}/bimg/${store.state.app.language}/slogon.png`
      }
    }, { immediate: true })

    watch(() => store.state.app.language, (n, o) => {
      if (n && n !== o && store.state.app.config.resource) {
        dataMap.slogonImage = `${store.state.app.config.resource}/bimg/${n}/slogon.png`
      }
    }, { immediate: true })

    return {
      t,
      lightLine,
      pagination,
      ...toRefs(dataMap)
    }
  }
})
