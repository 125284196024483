
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import man from '@/assets/images/magician.png'
import { useStore } from '@/store'

export default defineComponent({
  setup() {
    const cardsRef = ref(null)
    const lightRef = ref(null)
    const store = useStore()
    const imageList = computed(() => {
      const list = []
      for (let i = 1; i <= 24; i++) {
        // eslint-disable-next-line
        list.push(require(`@/assets/images/small_cards/fg_small_puke_${i}.png`))
      }
      return list
    })
    const lightList = computed(() => {
      const list = []
      for (let i = 1; i <= 12; i++) {
        // eslint-disable-next-line
        list.push(require(`@/assets/images/light/fg_begain_${i}.png`))
      }
      return list
    })
    const effect: any = {
      card() {
        //
      },
      ligh() {
        //
      }
    }
    const action = () => {
      const cardAction = {
        img: [...(cardsRef.value as any).children],
        index: 0,
        timer: -1,
        fn: () => {
          const that = cardAction
          that.timer && window.clearInterval(that.timer)
          that.timer = window.setInterval(() => {
            for (let i = 0, len = that.img.length; i < len; i++) {
              that.img[i].style.visibility = that.index === i ? 'visible' : 'hidden'
            }
            that.index += 1
            if (that.index >= 24) that.index = 0
          }, 150)
        }
      }
      const lightAction = {
        img: [...(lightRef.value as any).children],
        index: 0,
        timer: -1,
        fn: () => {
          const that = lightAction
          that.timer && window.clearInterval(that.timer)
          that.timer = window.setInterval(() => {
            for (let i = 0, len = that.img.length; i < len; i++) {
              that.img[i].style.display = that.index === i ? 'block' : 'none'
            }
            that.index += 1
            if (that.index >= 12) {
              that.index = 0
              for (let i = 0, len = that.img.length; i < len; i++) {
                that.img[i].style.display = 'none'
              }
              cardAction.fn()
              window.clearInterval(that.timer)
            }
          }, 150)
        }
      }
      return {
        cardAction,
        lightAction
      }
    }

    onMounted(() => {
      const el = window.document.querySelector('.J_Magician')
      if (store.state.app.device === 1 && el) {
        el.classList.add('m-magician')
      }
      const { lightAction } = action()
      // effect.card = cardAction
      effect.light = lightAction
    })

    watch(() => store.state.app.light, (n) => {
      if (n) effect.light.fn()
    })

    return {
      man,
      cardsRef,
      imageList,
      lightRef,
      lightList
    }
  }
})
