
import { defineComponent, reactive, toRefs, watch, onBeforeUnmount, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import Carousel from './components/Carousel.vue'
import Magician from './components/Magician.vue'
import Hot from './components/Hot.vue'
import Explain from './components/Explain.vue'
import Advantage from './components/Advantage.vue'
import Contact from './components/Contact.vue'
import SVGA from 'svgaplayerweb'
import { useStore } from '@/store'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: {
    Carousel,
    Magician,
    Hot,
    Explain,
    Advantage,
    Contact
  },
  setup() {
    const { t } = useI18n()
    const store = useStore()
    const router = useRouter()
    const dataMap = reactive({
      player: null as any,
      parsar: null as any,
      timer: -1
    })
    const methods = reactive({
      async loadImage(url: string, lang: string) {
        dataMap.player = new SVGA.Player('.J_SVGA')
        dataMap.parsar = new SVGA.Parser()
        await dataMap.parsar.load(
          `${url}/bimg/${lang}/demo_fx.svga?v=${new Date().getTime()}`, (videoItem: any) => {
            dataMap.player.setVideoItem(videoItem)
            dataMap.player.startAnimation()
          }
        )
      },
      onClick() {
        router.push('/game')
      }
    })

    onMounted(() => {
      const el = window.document.querySelector('.J_Home')
      if (store.state.app.device === 1 && el) {
        el.classList.add('m-home')
      }
    })

    watch(() => store.state.app.language, (lang) => {
      methods.loadImage(store.state.app.config.resource, lang)
    })

    watch(() => store.state.app.config.resource, (n, o) => {
      if (n && n !== o) {
        let count = 0
        dataMap.timer && window.clearInterval(dataMap.timer)
        dataMap.timer = window.setInterval(() => {
          if (window.document.querySelector('.J_SVGA')) {
            methods.loadImage(n, store.state.app.language)
            window.clearInterval(dataMap.timer)
          }
          count += 1
          if (count > 100) window.clearInterval(dataMap.timer)
        }, 300)
      }
    }, { immediate: true })

    onBeforeUnmount(() => {
      dataMap.player = null
      dataMap.parsar = null
    })

    return {
      t,
      ...toRefs(methods)
    }
  }
})
