import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-31ea94b8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "magician J_Magician" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  class: "cards",
  ref: "cardsRef"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  class: "light",
  ref: "lightRef"
}
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", { src: _ctx.man }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.imageList, (index) => {
        return (_openBlock(), _createElementBlock("img", {
          key: index,
          src: index
        }, null, 8, _hoisted_4))
      }), 128))
    ], 512),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lightList, (index) => {
        return (_openBlock(), _createElementBlock("img", {
          key: index,
          src: index,
          style: {"display":"none"}
        }, null, 8, _hoisted_6))
      }), 128))
    ], 512)
  ]))
}