import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-31aa2a43"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "advantage J_Advantage" }
const _hoisted_2 = { class: "light" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "container" }
const _hoisted_5 = { class: "inner" }
const _hoisted_6 = { class: "my-advantage" }
const _hoisted_7 = { class: "title" }
const _hoisted_8 = { class: "info" }
const _hoisted_9 = { class: "case-title mark" }
const _hoisted_10 = { class: "big" }
const _hoisted_11 = { class: "big mark" }
const _hoisted_12 = { class: "big" }
const _hoisted_13 = { class: "slogon" }
const _hoisted_14 = ["src"]
const _hoisted_15 = { class: "case-title" }
const _hoisted_16 = { class: "mark" }
const _hoisted_17 = { class: "big" }
const _hoisted_18 = { class: "mark" }
const _hoisted_19 = { class: "big" }
const _hoisted_20 = { class: "big mark" }
const _hoisted_21 = { class: "slogon" }
const _hoisted_22 = ["src"]
const _hoisted_23 = { class: "case-title mark" }
const _hoisted_24 = { class: "big" }
const _hoisted_25 = { class: "big mark" }
const _hoisted_26 = { class: "big mark" }
const _hoisted_27 = { class: "slogon" }
const _hoisted_28 = ["src"]
const _hoisted_29 = { class: "case-title mark" }
const _hoisted_30 = { class: "big" }
const _hoisted_31 = { class: "big mark" }
const _hoisted_32 = { class: "slogon" }
const _hoisted_33 = ["src"]
const _hoisted_34 = { class: "case-title" }
const _hoisted_35 = { class: "mark" }
const _hoisted_36 = { class: "big" }
const _hoisted_37 = { class: "mark" }
const _hoisted_38 = { class: "big" }
const _hoisted_39 = { class: "big mark" }
const _hoisted_40 = { class: "slogon" }
const _hoisted_41 = ["src"]
const _hoisted_42 = { class: "case-title" }
const _hoisted_43 = { class: "mark" }
const _hoisted_44 = { class: "big" }
const _hoisted_45 = { class: "mark" }
const _hoisted_46 = { class: "big" }
const _hoisted_47 = { class: "big mark" }
const _hoisted_48 = { class: "slogon" }
const _hoisted_49 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SwiperSlide = _resolveComponent("SwiperSlide")!
  const _component_Swiper = _resolveComponent("Swiper")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", { src: _ctx.lightLine }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("h3", _hoisted_7, _toDisplayString(_ctx.t('nav.ourAdvantage')), 1),
          _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.t('home.securityTeam')), 1)
        ]),
        _createVNode(_component_Swiper, {
          pagination: _ctx.pagination,
          loop: "",
          class: "advantage-swiper"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_SwiperSlide, { class: "case case1" }, {
              default: _withCtx(() => [
                _createElementVNode("p", _hoisted_9, [
                  _createElementVNode("strong", null, _toDisplayString(_ctx.t('home.html5Teconology')), 1)
                ]),
                _createElementVNode("p", null, [
                  _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.t('home.fgGame')), 1),
                  _createTextVNode(" " + _toDisplayString(_ctx.t('home.perfect')) + " ", 1),
                  _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.t('home.compatibel')), 1)
                ]),
                _createElementVNode("p", null, _toDisplayString(_ctx.t('home.device')), 1),
                _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.t('home.supportScreen')), 1),
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("img", { src: _ctx.slogonImage }, null, 8, _hoisted_14)
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_SwiperSlide, { class: "case case2" }, {
              default: _withCtx(() => [
                _createElementVNode("p", _hoisted_15, [
                  _createElementVNode("strong", _hoisted_16, _toDisplayString(_ctx.t('home.figure')), 1),
                  _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.t('home.and')), 1),
                  _createElementVNode("strong", _hoisted_18, _toDisplayString(_ctx.t('home.fluctuation')), 1)
                ]),
                _createElementVNode("p", null, _toDisplayString(_ctx.t('home.rtpSetting')), 1),
                _createElementVNode("p", null, [
                  _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.t('home.adjust')), 1),
                  _createTextVNode(" " + _toDisplayString(_ctx.t('home.fluctuations')), 1)
                ]),
                _createElementVNode("p", null, [
                  _createTextVNode(_toDisplayString(_ctx.t('home.achieve')) + " ", 1),
                  _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.t('home.balance')), 1)
                ]),
                _createElementVNode("div", _hoisted_21, [
                  _createElementVNode("img", { src: _ctx.slogonImage }, null, 8, _hoisted_22)
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_SwiperSlide, { class: "case case3" }, {
              default: _withCtx(() => [
                _createElementVNode("p", _hoisted_23, _toDisplayString(_ctx.t('home.experience')), 1),
                _createElementVNode("p", null, _toDisplayString(_ctx.t('home.perfectSound')), 1),
                _createElementVNode("p", null, [
                  _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.t('home.multiNode')), 1),
                  _createTextVNode(" " + _toDisplayString(_ctx.t('home.deployment')), 1)
                ]),
                _createElementVNode("p", null, [
                  _createTextVNode(_toDisplayString(_ctx.t('home.for')) + " ", 1),
                  _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.t('home.optimal')), 1),
                  _createTextVNode(" " + _toDisplayString(_ctx.t('home.performance')) + " ", 1),
                  _createElementVNode("span", _hoisted_26, _toDisplayString(_ctx.t('home.extreme')), 1),
                  _createTextVNode(" " + _toDisplayString(_ctx.t('home.expery')), 1)
                ]),
                _createElementVNode("div", _hoisted_27, [
                  _createElementVNode("img", { src: _ctx.slogonImage }, null, 8, _hoisted_28)
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_SwiperSlide, { class: "case case4" }, {
              default: _withCtx(() => [
                _createElementVNode("p", _hoisted_29, _toDisplayString(_ctx.t('home.customizedService')), 1),
                _createElementVNode("p", null, _toDisplayString(_ctx.t('home.playerPreference')), 1),
                _createElementVNode("p", null, [
                  _createTextVNode(_toDisplayString(_ctx.t('home.provide')) + " ", 1),
                  _createElementVNode("span", _hoisted_30, _toDisplayString(_ctx.t('home.multiCurrency')), 1)
                ]),
                _createElementVNode("p", null, [
                  _createElementVNode("span", _hoisted_31, _toDisplayString(_ctx.t('home.customized')), 1)
                ]),
                _createElementVNode("div", _hoisted_32, [
                  _createElementVNode("img", { src: _ctx.slogonImage }, null, 8, _hoisted_33)
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_SwiperSlide, { class: "case case5" }, {
              default: _withCtx(() => [
                _createElementVNode("p", _hoisted_34, [
                  _createElementVNode("strong", _hoisted_35, _toDisplayString(_ctx.t('home.access')), 1),
                  _createElementVNode("span", _hoisted_36, _toDisplayString(_ctx.t('home.and')), 1),
                  _createElementVNode("strong", _hoisted_37, _toDisplayString(_ctx.t('home.service')), 1)
                ]),
                _createElementVNode("p", null, _toDisplayString(_ctx.t('home.flexibleAPI')), 1),
                _createElementVNode("p", null, [
                  _createElementVNode("span", _hoisted_38, _toDisplayString(_ctx.t('home.flexibleProvide')), 1),
                  _createTextVNode(" " + _toDisplayString(_ctx.t('home.provide7x24')), 1)
                ]),
                _createElementVNode("p", null, [
                  _createTextVNode(_toDisplayString(_ctx.t('home.customerService')) + " ", 1),
                  _createElementVNode("span", _hoisted_39, _toDisplayString(_ctx.t('home.technicalSupport')), 1)
                ]),
                _createElementVNode("div", _hoisted_40, [
                  _createElementVNode("img", { src: _ctx.slogonImage }, null, 8, _hoisted_41)
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_SwiperSlide, { class: "case case6" }, {
              default: _withCtx(() => [
                _createElementVNode("p", _hoisted_42, [
                  _createElementVNode("strong", _hoisted_43, _toDisplayString(_ctx.t('home.data')), 1),
                  _createElementVNode("span", _hoisted_44, _toDisplayString(_ctx.t('home.dataAnd')), 1),
                  _createElementVNode("strong", _hoisted_45, _toDisplayString(_ctx.t('home.risk')), 1)
                ]),
                _createElementVNode("p", null, _toDisplayString(_ctx.t('home.professional')), 1),
                _createElementVNode("p", null, [
                  _createElementVNode("span", _hoisted_46, _toDisplayString(_ctx.t('home.improves')), 1),
                  _createTextVNode(" " + _toDisplayString(_ctx.t('home.efficiency')), 1)
                ]),
                _createElementVNode("p", null, [
                  _createTextVNode(_toDisplayString(_ctx.t('home.multiDimensional')) + " ", 1),
                  _createElementVNode("span", _hoisted_47, _toDisplayString(_ctx.t('home.safe')), 1),
                  _createTextVNode(" " + _toDisplayString(_ctx.t('home.operation')), 1)
                ]),
                _createElementVNode("div", _hoisted_48, [
                  _createElementVNode("img", { src: _ctx.slogonImage }, null, 8, _hoisted_49)
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["pagination"])
      ])
    ])
  ]))
}