import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9808915e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "explain J_Explain" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "desciption" }
const _hoisted_4 = {
  class: "cards",
  ref: "cardsRef"
}
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "words" }
const _hoisted_7 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("img", {
      src: _ctx.logo,
      class: "logo"
    }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("span", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.imageList, (index) => {
          return (_openBlock(), _createElementBlock("img", {
            key: index,
            src: index
          }, null, 8, _hoisted_5))
        }), 128))
      ], 512),
      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.t('home.fgGaming')), 1)
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("img", { src: _ctx.slogonImage }, null, 8, _hoisted_7)
    ])
  ]))
}